import { PutujmoSite } from '../swagger/putujmoAdminService'

export enum LanguageEnum {
	Serbian = 'sr',
	English = 'en',
	German = 'de',
	Rusian = 'ru',
	Romanian = 'ro',
}

export const languageOptions = [
	{ id: LanguageEnum.Serbian, text: 'Srpski' },
	{ id: LanguageEnum.English, text: 'Engleski' },
	{ id: LanguageEnum.German, text: 'Nemački' },
	{ id: LanguageEnum.Rusian, text: 'Ruski' },
	{ id: LanguageEnum.Romanian, text: 'Rumunski' },
]

export const siteOptions = [
	{ id: PutujmoSite.Serbia, text: 'Putujmo po Srbiji' },
	{ id: PutujmoSite.Montenegro, text: 'Putujmo Crna Gora' },
	{ id: PutujmoSite.OnlineRezervacije, text: 'Online rezervacije' }
]